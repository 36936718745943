import { type LucideIcon } from "lucide-solid";
import { ProgressCircle } from "../solid-ui/progress-circle";

interface MetricCardProps {
  title: string;
  value: string;
  trend: number;
  icon: LucideIcon;
  color: string;
}

export function MetricCard({
  title,
  value,
  trend,
  icon: Icon,
  color,
}: MetricCardProps) {
  return (
    <div class="bg-white dark:bg-gray-800/20 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700">
      <div class="flex justify-between items-start mb-4">
        <div>
          <p class="text-sm font-medium text-gray-500 dark:text-gray-400">
            {title}
          </p>
          <h3 class="text-2xl font-bold mt-1 dark:text-white">{value}</h3>
        </div>
        <div class={`p-3 rounded-lg ${color}`}>
          <Icon class="w-6 h-6" />
        </div>
      </div>
      <div class="flex items-center">
        <span
          class={`text-sm font-medium ${
            trend >= 0
              ? "text-green-600 dark:text-green-400"
              : "text-red-600 dark:text-red-400"
          }`}
        >
          {trend >= 0 ? "+" : ""}
          {trend}%
        </span>
        <span class="text-sm text-gray-500 dark:text-gray-400 ml-2">
          vs last week
        </span>
      </div>
    </div>
  );
}
export function ProgressMeasure({
  title,
  value,
  trend,
  icon: Icon,
  color,
}: MetricCardProps) {
  return (
    <div class="bg-white dark:bg-gray-800/20 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700">
      <div class="flex justify-between items-start mb-4">
        <div>
          <p class="text-sm font-medium text-gray-500 dark:text-gray-400">
            {title}
          </p>
          <ProgressCircle value={75} strokeWidth={3} size="sm">
            <span class="text-xs font-medium text-neutral-200">75%</span>
          </ProgressCircle>
        </div>
        <div class={`p-3 rounded-lg ${color}`}>
          <Icon class="w-6 h-6" />
        </div>
      </div>
      <div class="flex items-center">
        <span
          class={`text-sm font-medium ${
            trend >= 0
              ? "text-green-600 dark:text-green-400"
              : "text-red-600 dark:text-red-400"
          }`}
        >
          {trend >= 0 ? "+" : ""}
          {trend}%
        </span>
        <span class="text-sm text-gray-500 dark:text-gray-400 ml-2">
          vs last week
        </span>
      </div>
    </div>
  );
}

// export function ProgressMeasure() {
//   return (
//     <div class="flex items-center justify-start space-x-5">
//       <ProgressCircle value={75}>
//         <span class="text-xs font-medium text-slate-700">75%</span>
//       </ProgressCircle>
//       <div>
//         <p class="text-tremor-default text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">
//           $340/$450 (75%)
//         </p>
//         <p class="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
//           Spend management control
//         </p>
//       </div>
//     </div>
//   );
// }
