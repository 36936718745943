"use server";

import { eq } from "drizzle-orm";
import { metrics } from "./schemas";
import { db } from "./setup";

export async function getStatsFromUser(userId: string) {
  const result = await db
    .select({
      owner: metrics.owner,
      type: metrics.type,
      created_at: metrics.created_at,
      to: metrics.to,
      event_date: metrics.event_date,
      subject: metrics.subject,
    })
    .from(metrics)
    .where(eq(metrics.owner, userId));

  return result;
}
